.verticalTimelineElementTitle {
    color: #222
}

.verticalTimelineElementSubtitle {
    color: #888
}

.verticalTimelineElementText {
    color: #222
}

.ggLogo {
    margin-bottom: 12px;
    margin-left: 24px;
}